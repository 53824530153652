import { getStateValueStrings } from "@pollen/ui-library/client/shared/wizard/wizard.utils";
import { useMachine } from "@xstate/vue";
import get from "lodash/get";
import { assign, createMachine } from "xstate";

import AdditionalInformationStep from "~/business-areas/onboarding/components/steps/onboarding-additional-information.vue";
import CategorySelectionStep from "~/business-areas/onboarding/components/steps/onboarding-category-selection.vue";
import PersonaSelectionStep from "~/business-areas/onboarding/components/steps/onboarding-persona-selection.vue";
import PersonalInformationStep from "~/business-areas/onboarding/components/steps/onboarding-personal-information.vue";
import SubCategorySelectionStep from "~/business-areas/onboarding/components/steps/onboarding-subcategory-selection.vue";
import type {
  OnboardingWizardContext,
  OnboardingWizardEvent,
  OnboardingWizardOptions,
} from "~/business-areas/onboarding/onboarding.model";
import type { TagWithRelatedCoursesCount } from "~/business-areas/tag/tag.model";

export function useOnboardingWizard(options: OnboardingWizardOptions) {
  const { $analytics } = useNuxtApp();

  const {
    update,
    updatePreferences,
    updateProfile,
    me,
    hasCompletePersonalInformation,
  } = useEnrichedCurrentUser();

  const context: OnboardingWizardContext = {
    interests: [] as TagWithRelatedCoursesCount[],
    additionalInformation:
      me.value?.profile?.additional_information ?? undefined,
  };

  const machine = createMachine({
    id: "onboarding_wizard",

    types: {} as {
      events: OnboardingWizardEvent;
    },

    context,

    initial: hasCompletePersonalInformation.value
      ? "persona_selection"
      : "personal_information",

    states: {
      personal_information: {
        meta: { component: PersonalInformationStep },
        on: {
          personal_information_submitted: {
            target: options?.personalInformationOnly
              ? undefined
              : "persona_selection",
            async actions({
              event: {
                form: { preferences, ...request },
              },
              context,
            }) {
              $analytics.track("ob_personal_information_submitted");
              await updatePreferences(preferences);
              await update(request);

              if (!options?.personalInformationOnly) return;

              return options?.onComplete?.({ context });
            },
          },
        },
      },

      persona_selection: {
        meta: { component: PersonaSelectionStep },
        on: {
          back: hasCompletePersonalInformation.value
            ? undefined
            : "personal_information",
          personas_selected: {
            target: "category_selection",
            actions({ event }) {
              $analytics.track("ob_persona_selected", {
                personas: event.personas,
              });

              return updateProfile({
                personas: event.personas,
              });
            },
          },
        },
      },

      category_selection: {
        meta: { component: CategorySelectionStep },
        on: {
          back: "persona_selection",
          tags_selected: {
            target: "subcategory_selection",
            actions: [
              assign({
                interests: ({ event }) => event.tags,
              }),
              ({ event, context }) => {
                $analytics.track("ob_interests_categories_selected", {
                  values: event.tags.map((tag) => tag.slug),
                });

                return updateProfile({
                  interests: context.interests.map((tag) => tag.slug),
                });
              },
            ],
          },
        },
      },

      subcategory_selection: {
        meta: { component: SubCategorySelectionStep },
        on: {
          back: "category_selection",
          tags_selected: {
            target: "additional_information",
            actions: [
              assign({
                interests: ({ event, context }) => [
                  ...context.interests.filter((tag) => tag.depth === 0),
                  ...event.tags,
                ],
              }),
              ({ event, context }) => {
                $analytics.track("ob_interests_subcategories_selected", {
                  values: event.tags.map((tag) => tag.slug),
                });

                return updateProfile({
                  interests: context.interests.map((tag) => tag.slug),
                });
              },
            ],
          },
        },
      },

      additional_information: {
        meta: { component: AdditionalInformationStep },
        on: {
          back: "subcategory_selection",
          additional_information_provided: {
            actions: [
              assign({
                additionalInformation: ({ event }) => event.value,
              }),
              ({ context }) => {
                $analytics.track("ob_personal_information_submitted");
                $analytics.track("ob_completed");

                options?.onComplete?.({ context });

                return updateProfile({
                  additional_information: context.additionalInformation,
                });
              },
            ],
          },
        },
      },
    },
  });

  const { snapshot, send } = useMachine(machine);
  const currentStateNodeId = computed(() => {
    return getStateValueStrings(snapshot.value.value).reverse()[0];
  });

  const view = computed(() => {
    const stringifiedDeepestState =
      "onboarding_wizard." + currentStateNodeId.value;

    return get(snapshot.value.getMeta(), stringifiedDeepestState).component;
  });

  $analytics.track("ob_started", { origin: options.origin });

  return {
    state: snapshot,
    send,
    view,
  };
}
